import firebase from "firebase/app";
import {SetNToken,RemoveNToken} from '@/utils/auth'
import "firebase/messaging";
var firebaseConfig = {
  apiKey: "AIzaSyDzKd0OTYoU-_ryTahT2x0lB-t45aQyLpQ",
  authDomain: "gf-staff.firebaseapp.com",
  databaseURL: "https://gf-staff.firebaseio.com",
  projectId: "gf-staff",
  storageBucket: "gf-staff.appspot.com",
  messagingSenderId: "220817044596",
  appId: "1:220817044596:web:cde57331373a921d060d21",
  measurementId: "G-9GPW06CSDT"
};
// import {SetNToken} from '@/utils/auth'
var messaging = null;
var channel = null;
try {
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
  // messaging.usePublicVapidKey('BIHOgNy0pYFpU9ePc0kS0BsUZmg_feJN5jPiZH3TuMRpzzJb-S9pM2iBCMNJYkCkALqrvmbuWHN9U33vlDX2Z3U')
  var fcmInit = ()=>{ messaging
    .requestPermission()
    .then(function() {
  
      return messaging.getToken();
    })
    .then(function(token) {
      SetNToken(token);
    })
    .catch(function() {
      RemoveNToken()
    });
  }
  messaging.fcmInit = fcmInit;
  channel = new BroadcastChannel("firebase-messaging-sw.js");
} catch (error) {
  console.log(error);
  
}
 



 export var     FCM = messaging;
 export var     BChanel = channel;
